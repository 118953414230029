exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-dyrektor-marketingu-na-godziny-js": () => import("./../../../src/pages/dyrektor-marketingu-na-godziny.js" /* webpackChunkName: "component---src-pages-dyrektor-marketingu-na-godziny-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-tworca-marek-js": () => import("./../../../src/pages/tworca-marek.js" /* webpackChunkName: "component---src-pages-tworca-marek-js" */)
}

